import React from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";

const EntryScreen = ({ showInputs, categoryName, subcategoryName, handleInputChange, handleCloseButtonClick, handleClearButtonClick, handleSaveButtonClick }) => {
    return (
        <Form>
            <Row className="mb-3">
                <Col md="6">
                    <FormGroup>
                        <Label for="categoryName">Category Name</Label>
                        <Input
                            type="text"
                            id="categoryName"
                            value={categoryName}
                            onChange={(e) => handleInputChange("category", e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label for="subcategoryName">Subcategory Name</Label>
                        <Input
                            type="text"
                            id="subcategoryName"
                            value={subcategoryName}
                            onChange={(e) => handleInputChange("subcategory", e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col className="text-end">
                    <Button color="secondary" className="me-2" onClick={handleCloseButtonClick}>Close</Button>
                    <Button color="warning" className="me-2" onClick={handleClearButtonClick}>Clear</Button>
                    <Button color="success" onClick={handleSaveButtonClick}>Save</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default EntryScreen;
