import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { checkLogin, apiError } from '../../store/actions';
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import config from '../../config'; 
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function Login({ checkLogin, apiError, loginError }) {
    const [username, setUsername] = useState("admin@gmail.com");
    const [password, setPassword] = useState("123456");
    const navigate = useNavigate();

   
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/dashboard');
        } else {
            apiError("");
            document.body.classList.add("auth-body-bg");
            return () => {
                document.body.classList.remove("auth-body-bg");
            };
        }
    }, [navigate, apiError]);
    

    const handleSubmit = async (event, values) => {
        event.preventDefault();
        try {
            const response = await fetch(config.apiUrl+'/auth/admin/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: values.username,
                    password: values.password
                })
            });
    
            if (response.status === 200) {
                const responseData = await response.json(); 
    
                // Assuming your API returns a token upon successful login
                const token = responseData.accessToken;
                const {username, email} = responseData.user;
    
                // Save token and user data in localStorage
                localStorage.setItem('token', token);
                localStorage.setItem('username', username);
                localStorage.setItem('email', email);
                toastr.success('Login successful!', 'Welcome back');
                // Navigate to dashboard
                navigate('/dashboard');
            } else {
                const responseData = await response.json();
                apiError(responseData.message);
                toastr.warning(responseData.message);
            }
        } catch (error) {
            console.error('Login error:', error);
            apiError('Failed to login. Please check your credentials.');
        }
    }
    
    
    

    return (
        <>
            <div>
                <Container fluid className="p-0">
                    <Row className="g-0">
                        <Col lg={4}>
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <Row className="justify-content-center">
                                        <Col lg={9}>
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <Link to="/" className="">
                                                            <img src={logodark} alt="" height="20" className="auth-logo logo-dark mx-auto" />
                                                            <img src={logolight} alt="" height="20" className="auth-logo logo-light mx-auto" />
                                                        </Link>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                    <p className="text-muted">Sign in to continue to Admin Panel.</p>
                                                </div>
                                                {loginError && loginError ? <Alert color="danger">{loginError}</Alert> : null}
                                                <div className="p-2 mt-5">
                                                    <AvForm className="form-horizontal" onValidSubmit={handleSubmit}>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="username">Email</Label>
                                                            <AvField name="username" value={username} type="text" className="form-control" id="username" validate={{ email: true, required: true }} placeholder="Enter username" onChange={(e) => setUsername(e.target.value)} />
                                                        </div>

                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <Label htmlFor="userpassword">Password</Label>
                                                            <AvField name="password" value={password} type="password" className="form-control" id="userpassword" placeholder="Enter password" onChange={(e) => setPassword(e.target.value)} />
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Button color="primary" className="w-md waves-effect waves-light" type="submit">Log In</Button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</Link>
                                                        </div>
                                                    </AvForm>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    {/* <p>Don't have an account ? <Link to="/register" className="fw-medium text-primary"> Register </Link> </p> */}
                                                    <p>© 2024 Parrysmart. Crafted with <i className="mdi mdi-heart text-danger"></i> by Buildectron</p>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default connect(mapStateToProps, { checkLogin, apiError })(Login);
