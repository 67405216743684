import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, CardBody, Row, Col, Table, Input } from "reactstrap";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import config from "../../../config";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const ViewOrder = () => {
    const { id } = useParams();
    const [orderDetails, setOrderDetails] = useState({});
    const [status, setStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const breadcrumbItems = [
        { title: "Orders", link: "/orders" },
        { title: `View Order`, link: "#" },
    ];
    useEffect(() => {
        fetchOrderDetails(id);
    }, [id]);

    async function fetchOrderDetails(id) {
        try {
            const options = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') }
            }
            const response = await fetch(`${config.apiUrl}/orders/oid/${id}`, options);
            const data = await response.json();
            setOrderDetails(data.result);
            setStatus(data.result.status);
            setPaymentStatus(data.result.payment);
            if (data.result) {
                toastr.success("Orders Retrieved Successfully")
            } else {
                toastr.warning("No Records Found for Order")
            }
        } catch (error) {
            toastr.error("Something went wrong")
            console.error('Error fetching order data:', error);
        }
    }

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
        // You can add logic to update the status in the backend here
    }

    const handlePaymentStatusChange = (e) => {
        setPaymentStatus(e.target.value);
        // You can add logic to update the payment status in the backend here
    }

    return (
        <div className="page-content">
            <Container fluid>
            <Breadcrumbs title={`Order Details - ${orderDetails.orderId}`} breadcrumbItems={breadcrumbItems} />
                <Row>
                    <Col lg={8}>
                        <Card className="mb-3">
                        <CardBody className="border-bottom">
                            <h5 className="mb-2 font-size-16 ">Customer Details</h5>
                            <div className="customer-details d-flex justify-content-between">
                                <div className="d-flex align-items-center">
                                    <i className="mdi mdi-account font-size-18 p-2"></i>
                                    <p className="mb-0">{orderDetails.userId && orderDetails.userId.username}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <i className="mdi mdi-email font-size-18 p-2"></i>
                                    <p className="mb-0">{orderDetails.userId && orderDetails.userId.email}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <i className="mdi mdi-phone font-size-18 p-2"></i>
                                    <p className="mb-0">{orderDetails.userId && orderDetails.userId.phone}</p>
                                </div>
                            </div>
                        </CardBody>

                        <CardBody className="border-bottom">
                            <h5 className="mb-2 font-size-16 ">Product Details</h5>
                            <Table responsive striped className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Price (INR)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderDetails.products && orderDetails.products.map((product, index) => (
                                        <tr key={index}>
                                            <td>{product.productId.title}</td>
                                            <td>{product.quantity}</td>
                                            <td>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(product.productId.price)}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={2}><strong>Total:</strong></td>
                                        <td className="total-amount"><strong>{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(orderDetails.amount)}</strong></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className="mb-3">
                        <CardBody className="border-bottom">
                            <h5 className='font-size-16'>Shipping Address <i className="p-2 mdi mdi-truck-delivery font-size-18"></i></h5>
                            <div className="shipping-address">
                                <div className="mb-3">
                                    <strong>Street:</strong> {orderDetails.address && orderDetails.address.street}
                                </div>
                                <div className="mb-3">
                                    <strong>City:</strong> {orderDetails.address && orderDetails.address.city}
                                </div>
                                <div className="mb-3">
                                    <strong>State:</strong> {orderDetails.address && orderDetails.address.state}
                                </div>
                                <div>
                                    <strong>Zip Code:</strong> {orderDetails.address && orderDetails.address.zipCode}
                                </div>
                            </div>
                        </CardBody>
                        <CardBody className="border-bottom">
                            <h5 className='font-size-16'>Status</h5>
                            <Input type="select" name="status" value={status} onChange={handleStatusChange}>
                                <option value="pending">Pending</option>
                                <option value="processing">Processing</option>
                                <option value="shipped">Shipped</option>
                                <option value="delivered">Delivered</option>
                            </Input>
                            <h5 className="mt-4 font-size-16">Payment Status</h5>
                            <Input type="select" name="paymentStatus" value={paymentStatus} onChange={handlePaymentStatusChange}>
                                <option value="pending">Pending</option>
                                <option value="completed">Completed</option>
                                <option value="failed">Failed</option>
                            </Input>
                        </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default ViewOrder;
