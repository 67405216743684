import React, { useMemo, useEffect, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Card, CardBody, Container } from "reactstrap";
import { Link, useNavigate } from 'react-router-dom';
import config from "../../../config";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import UpdateProduct from './UpdateProduct';
import DeleteModal from "../../../components/helpercomponents/DeleteModel";

const Products = () => {
  const [tableData, setTableData] = useState([]);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editProductId, setEditProductId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTableData();
  }, []);

  async function fetchTableData() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') }
      }
      const response = await fetch(`${config.apiUrl}/products/admin/`,options);
      const data = await response.json();
      setTableData(data.result);
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  }

  const handleDeleteButtonClick = (id) => {
    setDeleteProductId(id);
    setModalIsOpen(true);
  };

  const confirmDelete = async () => {
    setModalIsOpen(false);
    if (deleteProductId) {
      const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') }
      };

      try {
        let url = `${config.apiUrl}/products/${deleteProductId}`
        const response = await fetch(url, requestOptions);
        if (response.ok) {
          await fetchTableData();
          toastr.success('Product deleted successfully.');
        } else {
          toastr.error('Failed to delete product.');
        }
      } catch (error) {
        console.error('Error:', error);
        toastr.error('Failed to delete product.');
      }
    }
  };

  const handleEditButtonClick = (id) => {
    setEditProductId(id);
  };

  const handleEditComplete = () => {
    setEditProductId(null);
    fetchTableData();
  };

  const columns = useMemo(
    () => [
      {
        Header: "Image",
        accessor: "image",
        disableFilters: true,
        filterable: false,
        Cell: ({ cell: { value } }) => {
          return <img src={value} alt="Product" style={{ width: "50px", height: "50px", display: "block", margin: "0 auto" }} />;
        },
      },
      {
        Header: "Product",
        accessor: "title",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Category",
        accessor: "categoryName",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Stock",
        accessor: "stock",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Price",
        accessor: "price",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Action",
        accessor: (cellProps) => {
          return (
            <React.Fragment>
              <Link to="#" className="me-3 text-primary" onClick={() => handleEditButtonClick(cellProps._id)}>
                <i className="mdi mdi-pencil font-size-18"></i>
              </Link>
              <Link to="#" className="text-danger" onClick={() => handleDeleteButtonClick(cellProps._id)}>
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Link>
            </React.Fragment>
          )
        },
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );

  return (
    <React.Fragment>
      {editProductId ?  
        <UpdateProduct 
          id={editProductId} 
          onComplete={handleEditComplete} 
        /> 
        :  
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Products"
              breadcrumbItems={[
                { title: "Product", link: "/ecommerce-products" },
                { title: "All Products", link: "#" },
              ]}
            />
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={tableData}
                  isPagination={false}
                  iscustomPageSize={false}
                  customPageSizeOptions={true}
                  isBordered={false}
                  customPageSize={10}
                  isGlobalFilter={true}
                  isAddProduct={true}
                  handleProductClicks={(e) => navigate('/ecommerce-add-product')}
                />
              </CardBody>
            </Card>
          </Container>
        </div>
      }

      <DeleteModal
          show={modalIsOpen}
          onDeleteClick={confirmDelete}
          onCloseClick={() => setModalIsOpen(false)}
      />
    </React.Fragment>
  );
};

export default Products;
