import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../store/actions';
import withRouter from '../../components/Common/withRouter';

class Logout extends Component {

    componentDidMount() {
        // Clear localStorage
        localStorage.clear();
        
        // Fire Action for Redirecting to Login Page
        setTimeout(() => {
            this.props.logoutUser(this.props.router.navigate);
        }, 100);
    }

    render() {
        return (
            <React.Fragment>
                <h1>&nbsp;</h1>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { logoutUser })(Logout));
