import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Card, CardBody, Container, Button, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import EntryScreen from "./CategoryEntryScreen"; 
import config from "../../../config";
import DeleteModal from "../../../components/helpercomponents/DeleteModel";

const EcommerceCategory = () => {
    const [categoryData, setCategoryData] = useState([]);
    const [showInputs, setShowInputs] = useState(false);
    const [categoryName, setCategoryName] = useState("");
    const [subcategoryName, setSubcategoryName] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteCategoryID, setDeleteCategoryID] = useState(null);
    const [deleteSubcategoryID, setDeleteSubcategoryID] = useState(null);

    useEffect(() => {
        fetchCategoryData();
    }, []);

    async function fetchCategoryData() {
        try {
            const response = await fetch(`${config.apiUrl}/category`);
            const data = await response.json();
            setCategoryData(data.result);
        } catch (error) {
            console.error('Error fetching category data:', error);
        }
    }

    const handleInputChange = (type, value) => {
        if (type === "category") {
            setCategoryName(value);
        } else if (type === "subcategory") {
            setSubcategoryName(value);
        }
    };

    const handleCloseButtonClick = () => {
        setShowInputs(false);
        setCategoryName("");
        setSubcategoryName("");
    };

    const handleClearButtonClick = () => {
        setCategoryName("");
        setSubcategoryName("");
    };

    const handleSaveButtonClick = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') },
            body: JSON.stringify({
                categoryName: categoryName,
                subcategoryName: subcategoryName
            })
        };
        
        try {
            const response = await fetch(`${config.apiUrl}/category`, requestOptions);
            const data = await response.json();
        } catch (error) {
            console.error('Error:', error);
        }
        
        setShowInputs(false);
        setCategoryName("");
        setSubcategoryName("");
        fetchCategoryData();
    };

    const handleAddButtonClick = () => {
        setShowInputs(true);
    };

    const handleDeleteButtonClick = async (categID, SubCategID) => {
        setDeleteCategoryID(categID);
        setDeleteSubcategoryID(SubCategID);
        setDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') }
        };
        
        try {
            let url = `${config.apiUrl}/category/${deleteCategoryID}/subcategory/${deleteSubcategoryID}`;
            const response = await fetch(url, requestOptions);
            setShowInputs(false);
            setCategoryName("");
            setSubcategoryName("");
            fetchCategoryData();
        } catch (error) {
            console.error('Error:', error);
        }
        
        setDeleteModal(false);
        setDeleteCategoryID(null);
        setDeleteSubcategoryID(null);
    };

    const columns = useMemo(
        () => [
            {
                Header: "Category",
                accessor: "category",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Subcategory",
                accessor: "subcategory",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Action",
                accessor: (cellProps) => {
                    const handleEditClick = (row) => {
                        setShowInputs(true)
                        setCategoryName(row['category'])
                        setSubcategoryName(row['subcategory']) 
                    };
                    const handleDeleteClick = (row) => {
                        handleDeleteButtonClick(row['id'],row['subcategory_id'])
                    };
                    
    
                    return (
                        <React.Fragment>
                            <button className="btn btn-link me-3 text-primary" onClick={() => {handleEditClick(cellProps)}}>
                                <i className="mdi mdi-pencil font-size-18"></i>
                            </button>
    
                            <button className="btn btn-link text-danger" onClick={() => {handleDeleteClick(cellProps)}}>
                                <i className="mdi mdi-trash-can font-size-18"></i>
                            </button>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Category"
                        breadcrumbItems={[{ title: "Dashboard", link: "/dashboard" },{ title: "Category", link: "#" }]}
                    />
                    <Card>
                        <CardBody>
                            {/* {!showInputs &&
                                <Row className="mb-3">
                                    <Col xs="12">
                                        <Button color="primary" className="float-end" onClick={handleAddButtonClick}>Add New</Button>
                                    </Col>
                                </Row>
                            } */}
                            {showInputs &&
                                <EntryScreen
                                    showInputs={showInputs}
                                    categoryName={categoryName}
                                    subcategoryName={subcategoryName}
                                    handleInputChange={handleInputChange}
                                    handleCloseButtonClick={handleCloseButtonClick}
                                    handleClearButtonClick={handleClearButtonClick}
                                    handleSaveButtonClick={handleSaveButtonClick}
                                />
                            }
                            <TableContainer
                                columns={columns || []}
                                data={categoryData || []}
                                isPagination={false}
                                isGlobalFilter={true}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isAddCatg={!showInputs}
                                handleAddButtonClick={handleAddButtonClick}
                                customPageSizeOptions={true}

                            />
                        </CardBody>
                    </Card>
                </Container>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleConfirmDelete}
                    onCloseClick={() => setDeleteModal(false)}
                />
            </div>
        </>
    );
};

export default EcommerceCategory;
