import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input, Card, Row, Col } from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import config from "../../../config";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from "react-router-dom";
import Dropzone from 'react-dropzone';

const Slider = () => {
  const [settings, setSettings] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [PrimID, setPrimID] = useState(null);

  useEffect(() => {
    fetchSliderSettings();
  }, []);

  const fetchSliderSettings = async () => {
    try {
      setIsFetching(true);
      const response = await fetch(`${config.apiUrl}/settings/slider`);
      const data = await response.json();
      if (data && data.type === "success" && data.result ) {
        setSettings(data.result);
        setPrimID(data.result._id)
      } else {
        toastr.error("Failed to fetch slider settings");
      }
    } catch (error) {
      console.error('Error fetching slider settings:', error);
      toastr.error("Failed to fetch slider settings");
    } finally {
      setIsFetching(false);
    }
  };
const saveImage = async(e)=>{

    e.preventDefault();
    
    if (!this.validateFormData()) {
        return false;
    }
    
    try {
        const formData = new FormData();
        formData.append('href', this.state.formData.description);
        formData.append('image', this.state.formData.category);
        formData.append('imageOrder', this.state.formData.subcategory);
        formData.append('isActive', this.state.formData.price);
        formData.append('remarks', this.state.formData.mrp)

        const response = await fetch(`${config.apiUrl}/settings/slider/${PrimID}`, {
            method: 'PUT',
            headers: {
                'token': localStorage.getItem('token')
            },
            body: formData,
        });
  
        if (response.ok) {
            const responseData = await response.json();
            toastr.success(responseData.message);
        } else {
          const responseData = await response.json();
            toastr.warning( responseData.message);
        }
    } catch (error) {
        toastr.error('Something went wrong');
        console.error('Error making API call:', error);
    }

}
  const handleAcceptedFiles = (files) => {
    // Handle accepted files logic goes here
  };

  const handleDeleteImage = (index) => {
    // Handle delete image logic goes here
  };

  if (isFetching || !settings) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>{settings.sectionTitle}</h2>
      <p>{settings.sectionSubTitle}</p>
  
      <Dropzone
        onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
      >
        {({ getRootProps, getInputProps }) => (
          <div className="dropzone" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="dz-message needsclick">
              <div className="mb-3">
                <i className="display-4 text-muted ri-upload-cloud-2-line" />
              </div>
              <h4>Drop files here or click to upload.</h4>
            </div>
          </div>
        )}
      </Dropzone>
      <div className="dropzone-previews mt-3" id="file-previews">
        {selectedFiles.map((f, i) => (
          <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete" key={i + "-file"}>
            <div className="p-2">
              <Row className="align-items-center">
                <Col className="col-auto">
                  <img
                    data-dz-thumbnail=""
                    height="80"
                    className="avatar-sm rounded bg-light"
                    alt={f.name}
                    src={f.preview}
                  />
                </Col>
                <Col>
                  <Link to="#" className="text-muted font-weight-bold">
                    {f.name}
                  </Link>
                  <p className="mb-0">
                    <strong>{f.formattedSize}</strong>
                  </p>
                </Col>
                <Col className="col-auto">
                  <Button color="danger" onClick={() => handleDeleteImage(i)}>
                    Delete
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Slider;
