import React, { useMemo,useEffect,useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import config from "../../../config";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";

const EcommerceOrders = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchTableData();
  }, []);

  async function fetchTableData() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') }
      }
      const response = await fetch(`${config.apiUrl}/common/contactform/`,options);
      const data = await response.json();
      setTableData(data.result);
      if (data.result && data.result.length > 0) {
        toastr.success("Customers Fetched Successfully")
      } else {
        toastr.warning("No Records Found for Customers")
      }
    } catch (error) {
      toastr.error("Something went wrong")

      console.error('Error fetching category data:', error);
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name ",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Subject",
        accessor: "subject",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Message",
        accessor: "message",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Submitted At",
        accessor: "submittedAt",
        disableFilters: true,
        filterable: false,
      }
    ],
    []
  );

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Contact Form", link: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Submitted Contact Form"
            breadcrumbItems={breadcrumbItems}
          />
          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={tableData || []}
                isPagination={false}
                isGlobalFilter={true}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                customPageSizeOptions={true}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EcommerceOrders;
