import React, { useMemo, useEffect, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import config from "../../../config";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";

const EcommerceOrders = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchTableData();
  }, []);
  async function fetchTableData() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') }
      }
      const response = await fetch(`${config.apiUrl}/orders/`,options);
      const data = await response.json();
      setTableData(data.result);
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "orderId",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Billing Name",
        accessor: "name",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Total",
        accessor: "amount",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Payment",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => {
          const statusColors = {
            paid: 'success',
            unpaid: 'secondary',
            pending: 'warning',
            default: 'danger'
          };
      const badgeColor = statusColors[cellProps.payment] || statusColors.default;
          return (
            <span className={`badge bg-${badgeColor}-subtle text-${badgeColor} text-uppercase`}>
              {cellProps.payment}
            </span>
          );
        },
      },
      {
        Header: "Status",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => {
          const statusColors = {
            delivered: 'success',
            dispatched: 'secondary',
            pending:'warning',
            out_for_delivery: 'info',
            in_checkout:'primary',
            ready_to_pickup:'info',
            default: 'danger'
          };
      
          // Get the color for the current status
          const badgeColor = statusColors[cellProps.status] || statusColors.default;
      
          return (
            <span className={`badge bg-${badgeColor}-subtle text-${badgeColor} text-uppercase`}>
              {cellProps.status}
            </span>
          );
        },
      },
      {
        Header: "Invoice",
        accessor: (cellProps) => {
          return (
            <button className="btn btn-light btn-rounded">Invoice <i className="mdi mdi-download ms-2"></i></button>
          )
        },
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Action",
        accessor: (cellProps) => {
          return (
            <React.Fragment>
              <Link to={`/order/${cellProps._id}`} className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
            </React.Fragment>
          )
        },
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Orders", link: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Orders"
            breadcrumbItems={breadcrumbItems}
          />
          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={tableData || []}
                isPagination={false}
                isGlobalFilter={true}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                className="custom-header-css table align-middle table-nowrap"
                tableClassName="table-centered align-middle table-nowrap mb-0"
                theadClassName="text-muted table-light"
                customPageSizeOptions={true}

              />
            </CardBody>
          </Card>

          
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EcommerceOrders;
