import React, { useMemo,useEffect,useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import config from "../../../config";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Card, CardBody, Container } from "reactstrap";
import { Link } from "react-router-dom";

const EcommerceOrders = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchTableData();
  }, []);

  async function fetchTableData() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') }
      }
      const response = await fetch(`${config.apiUrl}/users`,options);
      const data = await response.json();
      setTableData(data.result);
      if (data.result && data.result.length > 0) {
        toastr.success("Customers Fetched Successfully")
      } else {
        toastr.warning("No Records Found for Customers")
      }
    } catch (error) {
      toastr.error("Something went wrong")

      console.error('Error fetching category data:', error);
    }
  }
  const columns = useMemo(
    () => [
      {
        Header: "Customer ",
        accessor: "username",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Phone",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Join Date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: false,
      },
      // {
      //   Header: "Action",
      //   accessor: (cellProps) => {
      //     return (
      //       <React.Fragment>
      //         <Link to="#" className="me-3 text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
      //         <Link to="#" className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
      //       </React.Fragment>
      //     )
      //   },
      //   disableFilters: true,
      //   filterable: false,
      // },
    ],
    []
  );

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Customers", link: "#" },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Customers"
            breadcrumbItems={breadcrumbItems}
          />
          <Card>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={tableData || []}
                isPagination={false}
                isGlobalFilter={true}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                customPageSizeOptions={true}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EcommerceOrders;
