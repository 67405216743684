import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import config from "../../../config";

const Slider = () => {
  const [images, setImages] = useState([]);


  useEffect(() => {
    fetchSliderImages();
  }, []);

  const fetchSliderImages = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/settings/slider`);
      const data = await response.json();
      if (data) {
        // this is my data here
      } else {
        toastr.error("Failed to fetch slider images");
      }
    } catch (error) {
      console.error('Error fetching slider images:', error);
      toastr.error("Failed to fetch slider images");
    }
  };

  return (
    <div>
    
    Contact details setings
    </div>
  );
};

export default Slider;
