import React, { Component } from "react";
import { Link } from 'react-router-dom';
import {Button,Card,CardBody,Col,Container,Input,Label,Row,Form,Spinner} from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";
import config from "../../../config";
import Breadcrumbs from "../../../components/Common/Breadcrumb";

// Form Editor
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
class EcommerceAddProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      selectedFiles: [],
      formData: {
        title: "",
        stock: "",
        minQty: "",
        price: "",
        mrp: "",
        category: "",
        subcategory: "",
        description: "",
        image:"",
        image_src:""
      },
      categories: [],
      subcategories: [],
      isCategorySelected: false
    };
  }

  async componentDidMount() {
    try {
      const categoryResponse = await fetch(`${config.apiUrl}/category?list=main&isOpt=1`);
      const categoryData = await categoryResponse.json();
      const categoryList = categoryData.result;
      this.setState({ categories: categoryList, subcategories: [] });
      this.fetchProductData(this.props.id); // Call fetchProductData here
    } catch (error) {
      console.error('Error fetching category data:', error);
    }
  }

  fetchProductData = async (id) => {
    try {
      const response = await fetch(`${config.apiUrl}/products/admin/${id}`);
      const result = await response.json();
      const data = result.result;
      if (data && data.category) {
        this.onChangeCategory({value:data.category})
      }
      
      this.setState(prevState => ({
        formData: {
          ...prevState.formData,
          title: data.title || "",
          category: data.category || "",
          subcategory: data.subcategory || "", // Ensure subcategory is set properly
          stock: data.stock || "",
          price: data.price || "",
          mrp: data.mrp || "",
          minQty: data.minQty || "",
          description: data.description || "",
          image: data.image || ""
        },
        selectedFiles:[{name:data.title,preview:data.image}]
      }));
    } catch (error) {
      console.error('Error fetching product data:', error);
    } 
  };

  handleSubcategoryChange = (selectedOption) => {
    const subcategoryId = selectedOption.value;
    this.setState(prevState => ({
      formData: { ...prevState.formData, subcategory: subcategoryId }
    }));
  };
  onChangeCategory = async (selectedOption) => {
    const categoryId = selectedOption.value;
    await this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        category: categoryId,
        subcategory: ""
      },
      isCategorySelected: true
    }));
  
    try {
      const subcategoryResponse = await fetch(`${config.apiUrl}/category?list=sub&category_id=${categoryId}`);
      const subcategoryData = await subcategoryResponse.json();
      const subcategoryList = subcategoryData.result;
      this.setState({ subcategories: subcategoryList });
    } catch (error) {
      console.error('Error fetching subcategory data:', error);
    }
  };
  

  handleDeleteImage = () => {
    this.setState({
      selectedFiles: [],
      formData: {
        ...this.state.formData,
        image: '', 
        image_src: '',
      },
    });
  };
  formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };
  
  handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );
  
    // Set the first file as image_src
    this.setState((prevState) => ({
      formData: {
        ...prevState.formData,
        image: files[0].name, 
        image_src: files[0], // Set the file object itself
      },
    }));
  
    this.setState({ selectedFiles: files });
  };
  
  handleChange = (e) => {
    const { name, value } = e.target;
  
    const numericRegex = /^\d*(\.\d{0,2})?$/;
  
    if ((name === 'mrp' || name === 'minQty' || name === 'stock' || name === 'price') && !numericRegex.test(value)) {
      toastr.warning(`Please enter numbers only for ${name}`);
    } else {
      this.setState((prevState) => ({
        formData: {
          ...prevState.formData,
          [name]: value,
        },
      }));
    }
  };

  handleChangeEditor = (event, editor) => {
    const description = editor.getData();
    this.setState(prevState => ({
      formData: {
        ...prevState.formData,
        description: description
      }
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    
    if (!this.validateFormData()) {
      this.setState({ isLoading: false });
        return false;
    }
    
    try {
        const formData = new FormData();
        formData.append('title', this.state.formData.title);
        formData.append('description', this.state.formData.description);
        formData.append('category', this.state.formData.category);
        formData.append('subcategory', this.state.formData.subcategory);
        formData.append('price', this.state.formData.price);
        formData.append('mrp', this.state.formData.mrp);
        formData.append('stock', this.state.formData.stock);
        formData.append('minQty', this.state.formData.minQty);
        formData.append('image', this.state.formData.image);
        formData.append('image_src', this.state.formData.image_src); // Append image_src to FormData
  
        const response = await fetch(`${config.apiUrl}/products/${this.props.id}`, {
            method: 'PUT',
            headers: {
                'token': localStorage.getItem('token')
            },
            body: formData,
        });
  
        if (response.ok) {
            const responseData = await response.json();
            toastr.success(responseData.message);
            this.props.onComplete()
        } else {
          const responseData = await response.json();
            toastr.warning( responseData.message);
        }
    } catch (error) {
        toastr.error('Something went wrong');
        console.error('Error making API call:', error);
    }finally {
        this.setState({ isLoading: false });
    }
};

validateFormData = () => {
    const { formData } = this.state;

    if (!formData.title) {
        toastr.warning('Please Enter Product Name');
        return false;
    }
    if (!formData.category) {
        toastr.warning('Please Enter Product Category');
        return false;
    }
    if (!formData.stock) {
        toastr.warning('Please Enter Product Stock (SKU)');
        return false;
    }
if (!formData.minQty) {
        toastr.warning('Please Enter Minimum Order Quantity for product');
        return false;
    }
    if (!formData.price) {
        toastr.warning('Please Enter Product Price');
        return false;
    }
if (!formData.mrp) {
        toastr.warning('Please Enter Product MRP');
        return false;
    }
    if (!formData.description) {
        toastr.warning('Please Enter Product Description');
        return false;
    }
    if (!formData.image) {
        toastr.warning('Please Attach Product Image');
        return false;
    }
    
    return true;
};

  
  

  render() {
const editorConfig = {
      toolbar: {
        items: [
          'undo',
          'redo',
          'heading',
          'inserttable',
          '|',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote'
        ]
      }
    };
    return (
      <>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={ <Button type="submit" color="info" className="me-1" 
              onClick={()=>this.props.onComplete()}>
              Show All Products
            </Button>} breadcrumbItems={[{ title: "Product", link: "#" },{ title: "Update Product", link: "#" }]} />
    
            <Row>
            <div className="m-1">
           
            </div>
           
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Form>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="title">
                          Product Name
                        </Label>
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          className="form-control"
                          value={this.state.formData.title}
                          onChange={this.handleChange}
                        />
                      </div>
    
                      <div className="row">
                        <div className="col-md-6">
                          <Label className="form-label">
                            Category
                          </Label>
                          <Select
                              className="flex-grow-1 me-2"
                              classNamePrefix="select2-selection"
                              placeholder="Select Category"
                              options={this.state.categories}
                              isSearchable
                              value={this.state.categories.find(category => category.value == this.state.formData.category)}
                              onChange={this.onChangeCategory}
                            />
                                </div>
                        <div className="col-md-6">
                          <Label className="form-label">
                            Sub Category
                          </Label>
                          <Select
                            className="flex-grow-1 me-2"
                            classNamePrefix="select2-selection"
                            placeholder="Select Sub Category"
                            options={this.state.subcategories}
                            value={this.state.subcategories.find(subcategory => subcategory.value == this.state.formData.subcategory)}
                            isSearchable
                            isDisabled={!this.state.categories || !this.state.isCategorySelected} 
                            onChange={(selectedOption) => {
                              this.setState((prevState) => ({
                                formData: {
                                  ...prevState.formData,
                                  subcategory: selectedOption.value,
                                },
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        
                        <div className="col-lg-3">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="stock"
                            >
                              Stock
                            </Label>
                            <Input
                              id="stock"
                              name="stock"
                              type="text"
                              className="form-control"
                              value={this.state.formData.stock}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="mb-3">
                            <Label
                              className="form-label"
                              htmlFor="minQty"
                            >
                              Minimum Order Quantity
                            </Label>
                            <Input
                              id="minQty"
                              name="minQty"
                              type="text"
                              className="form-control"
                              value={this.state.formData.minQty}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-3">
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="mrp">
                              Rate (MRP)
                            </Label>
                            <Input
                              id="mrp"
                              name="mrp"
                              type="text"
                              className="form-control"
                              value={this.state.formData.mrp}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>
                        
                        <div className="col-lg-3">
                          <div className="mb-3">
                            <Label className="form-label" htmlFor="price">
                              Price (Discount Price)
                            </Label>
                            <Input
                              id="price"
                              name="price"
                              type="text"
                              className="form-control"
                              value={this.state.formData.price}
                              onChange={this.handleChange}
                            />
                          </div>
                        </div>

                      </div>
    
                      {/* <div className="mb-3">
                        <label className="form-label" htmlFor="description">
                          Product Description
                        </label>
                        <textarea
                          className="form-control"
                          id="description"
                          name="description" // Ensure this matches the key in formData
                          rows="3"
                          value={this.state.formData.description}
                          onChange={this.handleChange}
                        ></textarea>
</div> */}

                <div className="mb-3">
                        <label className="form-label" htmlFor="description">
                          Product Description
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          config={editorConfig}
                          data={this.state.formData.description}
                          onChange={this.handleChangeEditor}
                        />
                      </div>
                    </Form>
    
                    {/* Product Images Form */}
                    <label className="form-label" htmlFor="description">
                          Product Image
                        </label>
                    <Form className="dropzone">
                      <Dropzone
                      
                        onDrop={(acceptedFiles) =>
                          this.handleAcceptedFiles(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div  >
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              
                                <div className="mb-3">
                                  <i className="display-4 text-muted ri-upload-cloud-2-line" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                                
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {this.state.selectedFiles.map((f, i) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  <Col className="col-auto">
                      {/* Delete button */}
                      <Button
                        color="danger"
                        onClick={this.handleDeleteImage}
                      >
                        Delete
                      </Button>
                    </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
    
    
                      </div>
                      
                      
                      </Form>
                  </CardBody>
                  <div className="text-center mt-8 mb-4">
                    <Button
                      type="submit"
                      color="primary"
                      className="me-1"
                      onClick={this.handleSubmit}
                      style={{ minHeight: "38px" }}
                      disabled={this.state.isLoading} // Disable the button when isLoading is true
                    >
                      <div className="d-flex align-items-center">
                        {this.state.isLoading ? (
                          <Spinner color="light" size="sm" style={{ width: "20px", height: "20px", marginRight: "5px" }} />
                        ) : null}
                        Update Changes
                      </div>
                    </Button>
                    <Button type="button" onClick={this.props.onComplete} color="light" style={{ minHeight: "38px" }}>
                      Cancel
                    </Button>
                  </div>


                </Card>
              </Col>
            </Row>
            
          </Container>
          
        </div>
      </>
    );
    
  }
}

export default EcommerceAddProduct;
