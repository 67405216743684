import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard
import Dashboard from "../pages/Dashboard/index";


//Ecommerce Pages
import EcommerceOrders from "../pages/Ecommerce/Orders/index";
import EcommerceViewOrders from "../pages/Ecommerce/Orders/ViewOrder";

import EcommerceCustomers from "../pages/Ecommerce/Customers/index";
import EcommerceShops from "../pages/Ecommerce/Shops/index";

import ContactForm from "../pages/Ecommerce/ContactForm/index";
import Setting from "../pages/Ecommerce/Setting/index";

import EcommerceAddProduct from "../pages/Ecommerce/Products/AddProduct";
import EcommerceUpdateProduct from "../pages/Ecommerce/Products/UpdateProduct";
import EcommerceAllProduct from "../pages/Ecommerce/Products/index";

import EcommerceCategory from "../pages/Ecommerce/Category/EcommerceCategory";


//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";



const authProtectedRoutes = [


	//Utility
	{ path: "/starter/:id", component: <StarterPage /> },
	{ path: "/timeline", component: <Timeline /> },
	{ path: "/faqs", component: <FAQs /> },
	{ path: "/pricing", component: <Pricing /> },

	

	//Ecommerce
	{ path: "/ecommerce-orders", component: <EcommerceOrders /> },
	{ path: "/order/:id", component: <EcommerceViewOrders /> },

	{ path: "/ecommerce-customers", component: <EcommerceCustomers /> },
	{ path: "/ecommerce-category", component: <EcommerceCategory /> },
	{ path: "/ecommerce-shops", component: <EcommerceShops /> },
	{ path: "/ecommerce-add-product", component: <EcommerceAddProduct /> },
	{ path: "/ecommerce-update-product/:id", component: <EcommerceUpdateProduct /> },
	
	{ path: "/ecommerce-products", component: <EcommerceAllProduct /> },

	{ path: "/dashboard", component: <Dashboard /> },

	{ path: "/contactform", component: <ContactForm /> },
	{ path: "/setting", component: <Setting /> },
	{ path: "/setting/:type", component: <Setting /> },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: <Navigate to="/login" /> },
];

const publicRoutes = [
	{ path: "/logout", component: <Logout /> },
	{ path: "/login", component: <Login /> },
	{ path: "/forgot-password", component: <ForgetPwd /> },
	{ path: "/register", component: <Register /> },
	{ path: "/lock-screen", component: <AuthLockScreen /> },

	{ path: "/maintenance", component: <Maintenance /> },
	{ path: "/comingsoon", component: <CommingSoon /> },
	{ path: "/404", component: <Error404 /> },
	{ path: "/*", component: <Error404 /> },
	{ path: "/500", component: <Error500 /> },
];

export { authProtectedRoutes, publicRoutes };
