import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Card, CardBody, Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const StarterPage = () => {
    const { id } = useParams(); // Access the id parameter from the route

    const breadcrumbItems = [
        { title : "Utility", link : "#" },
        { title : `Starter Page - ${id}`, link : "#" },
    ];

    console.log("ID:", id); // Log the id parameter

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={`Starter Page - ${id}`} breadcrumbItems={breadcrumbItems} />
               <Row>
                    <Col sm={8}>
                                <Card className="pricing-box">
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <div className="mt-3">
                                                <i className="ri-vip-crown-line text-primary h1"></i>
                                            </div>
                                            <h5 className="mt-4">Unlimited</h5>

                                            <div className="font-size-14 mt-4 pt-2">
                                                <ul className="list-unstyled plan-features">
                                                    <li>Free Live Support</li>
                                                    <li>Unlimited User</li>
                                                    <li>No Time Tracking</li>
                                                </ul>
                                            </div>

                                            <div className="mt-5">
                                                <h1 className="fw-bold mb-1"><sup className="me-1"><small>$</small></sup>49</h1>
                                                <p className="text-muted">Per month</p>
                                            </div>

                                            <div className="mt-5 mb-3">
                                                <Link to="#" className="btn btn-primary w-md">Get started</Link>
                                            </div>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
            </div>
        </>
    );
}

export default StarterPage;
