import React, { useEffect, useState } from "react";
import config from "../../../config";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Card, CardBody, Container } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Slider from "./Slider";
import ContactDetails from "./ContactDetails";
import './Setting.css'; // Import CSS file for styling

const Setting = () => {
  const [settings, setSettings] = useState([]);
  const { type } = useParams();

  useEffect(() => {
    fetchSettings();
  }, []);

  async function fetchSettings() {
    try {
      const options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'token': localStorage.getItem('token') }
      }
      const response = await fetch(`${config.apiUrl}/settings/menulist`, options);
      const data = await response.json();
      if (data.type === "success" && data.result && data.result.length > 0) {
        setSettings(data.result);
      } else {
        toastr.warning("No Records Found")
      }
    } catch (error) {
      toastr.error("Something went wrong")
      console.error('Error fetching category data:', error);
    }
  };

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Setting", link: "/setting" },
    { title: type, link: "#" },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Settings"
            breadcrumbItems={breadcrumbItems}
          />
          <Card>
            <CardBody>
              {/* Render settings based on the route parameter */}
              {type ? (
                type === "Slider" ? <Slider /> : type === "ContactDetails" ? <ContactDetails /> : null
              ) : (
                <>
                  {settings.map((setting, index) => (
                    <Link to={`/setting/${setting.sectionType}`} key={index} className="card-link">
                      <div className="setting-card-container">
                        <div className="setting-details">
                          <h5 className="text-primary">{setting.title}</h5>
                          <p>{setting.description}</p>
                        </div>
                        <i className={`${setting.icon} setting-icon text-primary`}></i>
                      </div>
                    </Link>
                  ))}
                </>
              )}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Setting;
